/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */
import React, { useState, useEffect, ReactNode } from "react";

import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import Loader from "@art/Loader.svg";
import LogoSVG from "@art/NarwhalLogoWithNameDark.svg";

// @ts-ignore
import { ExternalLink, SimpleMessage } from "dremio-ui-lib";

import ChangeOrgButton from "@components/ChangeOrgButton";
import ChangeEmailButton from "@components/ChangeEmailButton";

import { getInfo } from "@utils/apis/login";
import { deleteCookie, getAllCookies } from "@utils/cookieUtils";
import {
  AUTH_METHOD,
  COOKIE_CONSENT,
  ORG_ID,
  USERNAME,
} from "@constants/cookieConstants";
import {
  LOCAL,
  GITHUB,
  GOOGLE,
  MICROSOFT,
  AZURE_AD,
  OKTA,
} from "@constants/socialConstants";

import EmailForm from "./EmailForm";
import OrgSelectForm from "./OrgSelectForm";
import RedirectToSignUpPage from "./RedirectToSignUpPage";
import SocialAndEnterpriseForm from "./SocialAndEnterpriseForm";
import CookieConsent from "./CookieConsent";

import "./login.scss";

const VALID_ORGS = [
  "COMMISSIONING",
  "FIRST_CLOUD_ADDED",
  "CFT_STARTED",
  "CFT_COMPLETE",
  "ACTIVE",
];

type LocationState = {
  orgList: {
    orgId: string;
    orgName: string;
    authMethods: Array<
      | typeof LOCAL
      | typeof GITHUB
      | typeof GOOGLE
      | typeof MICROSOFT
      | typeof AZURE_AD
      | typeof OKTA
    >;
  }[];
  redirectParam: string;
  userNotFound: boolean;
};

const Login = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation<LocationState>();

  const [message, setMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);
  const [showMessage, setShowMessage] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [orgId, setOrgId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { state: locationState } = location || {};
  const { orgList = [], userNotFound } = locationState || {};
  const redirectParam =
    new URLSearchParams(location.search).get("redirect") || null;
  const redirectUrl = (redirectParam && new URL(redirectParam)) || null;

  const showCookiePreferences = () => setShowPreferences(true);

  useEffect(() => {
    if (!redirectParam) {
      setMessage({
        type: "error",
        message: formatMessage({ id: "Login.Error.MissingRedirect" }),
      });
      setShowMessage(true);
    }
  }, [redirectParam]); // eslint-disable-line

  useEffect(() => {
    redirectWithCookies();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (orgList.length === 1) {
      const { orgId: id } = orgList[0];
      setOrgId(id);
    }
  }, [orgList]);

  const redirectWithCookies = async () => {
    const {
      [COOKIE_CONSENT]: cookieConsent,
      [AUTH_METHOD]: cookieAuthMethod,
      [ORG_ID]: cookieOrgId,
      [USERNAME]: cookieUsername,
    } = getAllCookies();

    if (
      redirectParam &&
      cookieConsent === "true" &&
      cookieOrgId &&
      cookieAuthMethod &&
      cookieUsername &&
      cookieUsername !== "undefined"
    ) {
      try {
        setIsLoading(true);
        let validOrgFound = false;
        const orgInfo = await getInfo(cookieUsername);
        const modifiedOrgList = orgInfo.map(
          (org: { orgId: string; authMethods: string[] }) => {
            if (
              org.orgId === cookieOrgId &&
              org.authMethods.some(
                (authMethod: string) => authMethod === cookieAuthMethod
              )
            ) {
              org.authMethods = [cookieAuthMethod];
              validOrgFound = true;
            }
            return org;
          }
        );
        if (validOrgFound) {
          history.push({
            ...location,
            state: {
              ...locationState,
              redirectParam,
              orgList: modifiedOrgList,
            },
          });
          modifiedOrgList.length > 1 && setOrgId(cookieOrgId);
        } else {
          history.push({
            ...location,
            state: {
              ...locationState,
              redirectParam,
              orgList: [],
            },
          });
          deleteCookie(AUTH_METHOD);
          deleteCookie(ORG_ID);
          setOrgId(null);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const getRedirectUrlWithParams = (authMethod: string): string | void => {
    if (redirectUrl && orgId) {
      redirectUrl.searchParams.set("organization", orgId);
      redirectUrl.searchParams.set("provider", authMethod);
      return redirectUrl.toString();
    }
  };

  const handleLoginInfo = (orgsListResponse: any) => {
    const filtersOrgsList =
      orgsListResponse && Array.isArray(orgsListResponse)
        ? orgsListResponse.filter((org) => VALID_ORGS.includes(org.state))
        : orgsListResponse;
    if (filtersOrgsList && filtersOrgsList.length > 0) {
      history.push({
        ...location,
        state: {
          ...locationState,
          redirectParam,
          orgList: filtersOrgsList,
        },
      });
    } else if (
      filtersOrgsList &&
      (filtersOrgsList.status === 404 || filtersOrgsList.length === 0)
    ) {
      history.push({
        ...location,
        state: {
          ...locationState,
          userNotFound: true,
        },
      });
    } else {
      const errorMessage =
        (filtersOrgsList &&
          filtersOrgsList.data &&
          filtersOrgsList.data.errorMessage) ||
        formatMessage({ id: "Login.Error.Email" });
      setMessage({ type: "error", message: errorMessage });
      setShowMessage(true);
    }
  };

  const handleOrgSelect = async (id: string) => {
    const {
      [COOKIE_CONSENT]: cookieConsent,
      [AUTH_METHOD]: cookieAuthMethod,
      [ORG_ID]: cookieOrgId,
      [USERNAME]: cookieUsername,
    } = getAllCookies();

    if (
      redirectParam &&
      cookieConsent === "true" &&
      cookieOrgId &&
      cookieAuthMethod &&
      cookieUsername &&
      cookieUsername !== "undefined" &&
      cookieOrgId === id &&
      orgList.some(
        (org) =>
          org.orgId === id &&
          org.authMethods.length === 1 &&
          org.authMethods[0] === cookieAuthMethod
      )
    ) {
      try {
        setIsLoading(true);
        let orgInfo = await getInfo(cookieUsername);
        if (orgInfo.find((org: any) => org.orgId === id)) {
          setOrgId(id);
        } else {
          orgInfo = [];
          setOrgId(null);
          setMessage({
            type: "error",
            message: formatMessage({ id: "Login.Error.OrgNotFound" }),
          });
          setShowMessage(true);
          deleteCookie(AUTH_METHOD);
          deleteCookie(ORG_ID);
          deleteCookie(USERNAME);
        }
        history.push({
          ...location,
          state: {
            ...locationState,
            redirectParam,
            orgList: orgInfo,
          },
        });
        setOrgId(id);
        setIsLoading(false);
      } catch (error: any) {
        const errorMessage =
          (error && error.data && error.data.errorMessage) ||
          formatMessage({ id: "Login.Error.Email" });
        setMessage({ type: "error", message: errorMessage });
        setShowMessage(true);
        setIsLoading(false);
      }
    }
    setOrgId(id);
  };

  const handleDissmissMessage = () => {
    setMessage(null);
    setShowMessage(false);
  };

  const handleChangeEmail = () => {
    setOrgId(null);
    history.push({
      ...location,
      state: {
        ...locationState,
        orgList: [],
      },
    });
  };

  const renderLogo = () => (
    <img
      src={LogoSVG}
      className="login__logo"
      alt={formatMessage({ id: "Common.Logo.Alt" })}
      aria-label={formatMessage({ id: "Common.Logo.Alt" })}
    />
  );

  const renderEmailForm = () => (
    <EmailForm handleLoginInfo={handleLoginInfo} disabled={false} />
  );

  const renderOrgSelectForm = () => (
    <Grid container justify="center">
      <Grid item classes={{ root: "full-width" }}>
        <OrgSelectForm orgList={orgList} handleOrgSelect={handleOrgSelect} />
      </Grid>
    </Grid>
  );

  const renderSocialForm = (): ReactNode | void => {
    const { authMethods = [] } =
      orgList.find((org) => org.orgId === orgId) || {};
    if (!isLoading && !userNotFound && orgId) {
      return (
        <Grid container justify="flex-start">
          <Grid item>
            <SocialAndEnterpriseForm
              orgId={orgId}
              authMethods={authMethods}
              handleClick={(authMethod) => getRedirectUrlWithParams(authMethod)}
              isHrefComponent
            />
          </Grid>
        </Grid>
      );
    }
  };
  const renderRedirectToSignUpPage = () => <RedirectToSignUpPage />;

  const renderMessage = () =>
    showMessage && message ? (
      <div className="login__messageWrapper">
        <SimpleMessage
          type={message.type}
          message={message.message}
          handleDismiss={handleDissmissMessage}
        />
      </div>
    ) : (
      <div className="login__messagePlaceholder" />
    );

  const renderPrivacyPolicyLink = () => (
    <ExternalLink
      href={formatMessage({ id: "Login.PrivacyPolicyLink" })}
      className="link login__privacyPolicyLink "
    >
      {formatMessage({ id: "Login.PrivacyPolicy" })}
    </ExternalLink>
  );

  const renderTermsOfServiceLink = () => (
    <ExternalLink
      href={formatMessage({ id: "Login.TermsOfServiceLink" })}
      className="link login__privacyPolicyLink service-link margin-left gutter-left margin-right gutter-right"
    >
      {formatMessage({ id: "Login.TermsOfService" })}
    </ExternalLink>
  );

  const renderCookiePeferencesButton = () => (
    <span
      onClick={showCookiePreferences}
      className="loginPage__privacyPolicyLink link"
    >
      {formatMessage({ id: "Cookie.Preferences.CookiePreferences" })}
    </span>
  );

  const renderHeader = () => {
    let orgName;
    let title = "Login.Title";
    if (orgList.length && !orgId) {
      title = "Login.OrganizationTitle";
    }
    if (orgId) {
      const orgDetails = orgList && orgList.find((org) => org.orgId === orgId);
      title = "Login.Organization";
      orgName = orgDetails && orgDetails.orgName;
    }
    return !isLoading ? (
      <h1 className="loginForm__title">
        {formatMessage(
          {
            id: userNotFound ? "Login.OrgNotFoundPage.Title" : title,
          },
          { orgName: orgName || "Dremio" }
        )}
      </h1>
    ) : null;
  };

  const renderLoadingSpinner = () => (
    <div className="login__spinnerWrapper ">
      <img
        src={Loader}
        className="login__spinner"
        alt={formatMessage({ id: "Common.Loading" })}
      />
    </div>
  );

  let containerClass = "";

  if (orgList && orgList.length > 0) {
    containerClass += "--tall";
  }

  return (
    <div className="login">
      {renderLogo()}
      <Grid container>
        <div className={`loginForm__container ${containerClass}`}>
          {renderHeader()}
          <div className="loginForm__content">
            {renderMessage()}
            {isLoading && renderLoadingSpinner()}
            {!isLoading && userNotFound && renderRedirectToSignUpPage()}
            {!isLoading &&
              !userNotFound &&
              orgList.length === 0 &&
              renderEmailForm()}
            {!isLoading &&
              !userNotFound &&
              !orgId &&
              orgList.length > 0 &&
              renderOrgSelectForm()}
            {renderSocialForm()}
          </div>
          {orgId && <ChangeOrgButton handleClick={() => setOrgId(null)} />}
          {(orgList.length > 0 || userNotFound) && (
            <ChangeEmailButton handleClick={handleChangeEmail} />
          )}
          <div className="margin-top">
            {renderPrivacyPolicyLink()}
            {renderTermsOfServiceLink()}
            {renderCookiePeferencesButton()}
          </div>
        </div>
      </Grid>
      <CookieConsent
        setShowPreferences={setShowPreferences}
        showPreferences={showPreferences}
      />
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Login;
